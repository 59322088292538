import {
  AuthenticationStatus,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  connectorsForWallets,
  createAuthenticationAdapter,
  darkTheme,
  getDefaultConfig,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { SiweMessage } from 'siwe';
import { SignerProvider } from 'src/contexts/SignerContext';
import { SocketProvider } from 'src/contexts/SocketContext';
import { createContext } from 'src/helpers/context';
import { accessTokenIAO, removeAuthTokens } from 'src/helpers/storage';
import { returnRawValue, useLocalStorage } from 'src/hooks/useLocalStorage';
import { THEME_MODE } from 'src/interfaces/theme';
import { useLogin } from 'src/services/auth/login';
import { useLogout } from 'src/services/auth/logout';
import { useVerifyToken } from 'src/services/auth/verify';
import { userKeys } from 'src/services/user/user-keys';
import { useAppSelector } from 'src/store';
import { mainnet } from 'viem/chains';
import {
  CreateConnectorFn,
  ResolvedRegister,
  WagmiProvider,
  createConfig,
  http,
  useAccount,
  useChainId,
} from 'wagmi';
import { useRequestUpdateChainId } from '../services/user/request-update-chain-id';
import { useUpdateChainId } from '../services/user/update-chain-id';
import { bitLayerMainnet, bitLayerTestnet, sei, seiDevnet, sepoliaConfig } from './network-config';
import { queryClient } from './react-query';
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  trustWallet,
  coin98Wallet,
  coinbaseWallet,
  rabbyWallet,
  safeWallet,
} from '@rainbow-me/rainbowkit/wallets';
const useTestnet = process.env.REACT_APP_TESTNET === 'true';
export const getChains = (useTestnet: boolean) => {
  if (useTestnet) {
    return { ethereum: sepoliaConfig };
  }

  return { ethereum: mainnet };
};

export const chains = getChains(useTestnet);

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Wallets',
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        trustWallet,
        coin98Wallet,
        coinbaseWallet,
        walletConnectWallet,
        safeWallet,
        rabbyWallet,
      ],
    },
  ],
  {
    appName: 'Vdex',
    projectId: '18b664217a500896de2d3b2e396ccab3',
  },
) as CreateConnectorFn[];

export const wagmiConfig: ResolvedRegister['config'] = createConfig({
  connectors,
  chains: [chains.ethereum],
  transports: {
    1: http(), // Ethereum Mainnet
    200810: http(), // BitLayer
    11155111: http(), // Sepolia
    713715: http(), // Sei Testnet
    200901: http(), // BitLayer Testnet
    1329: http(), // Sei Devnet
  },
});

export const config = getDefaultConfig({
  appName: 'dex',
  projectId: '85e9ebb34585fc3bde5e0ed8cb752d6b',
  chains: [chains.ethereum],
  pollingInterval: 5000,
});

const [AuthStatusProvider, useAuthStatus] = createContext({
  hookName: 'useAuthStatus',
  providerName: 'AuthStatusProvider',
});

export const SiweProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  // const sdk = new SafeAppsSDK();
  // sdk.safe
  //   .getInfo()
  //   .then((info) => {
  //     console.log('Safe App Info:', info);
  //     // Perform Safe-specific logic here
  //   })
  //   .catch((error) => {
  //     console.error('Not running inside Safe App:', error);
  //   });
  const [accessToken] = useLocalStorage(accessTokenIAO, undefined, {
    serialize: returnRawValue,
    deserialize: returnRawValue,
  });

  const { address: userAddress } = useAccount();
  const verifyTokenQuery = useVerifyToken();
  const loginMutation = useLogin();
  const logoutMutation = useLogout({ onSettled: removeAuthTokens });
  const { mutateAsync: updateChainIdMutation } = useUpdateChainId();
  const requestUpdateChainIdMutation = useRequestUpdateChainId();
  // const setCombinePublicKeyMutation = useSetCombinePublicKey();
  const chainId = useChainId();
  const searchParams = new URLSearchParams(window.location.search);
  let referralCode = searchParams.get('referral');
  if (!referralCode) {
    referralCode = localStorage.getItem('accesscode');
  }

  console.log('🚀 ~ referralCode ~ referralCode:', referralCode);

  const authenticationAdapter = useMemo(
    () =>
      createAuthenticationAdapter({
        getNonce: async () => {
          return 'nonce';
        },

        createMessage: ({ address, chainId }) => {
          return new SiweMessage({
            domain: window.location.host,
            address,
            statement: 'Sign in with Ethereum to the app.',
            uri: window.location.origin,
            version: '1',
            chainId,
          });
        },

        getMessageBody: ({ message }) => {
          return message.prepareMessage();
        },

        verify: async ({ message, signature }) => {
          try {
            await loginMutation.mutateAsync({
              address: userAddress!,
              signature,
              message: message.prepareMessage(),
              referrerCode: referralCode ?? '',
              chainId: chainId,
            });
            await requestUpdateChainIdMutation
              .mutateAsync({
                chainId,
              })
              .then(() => {
                let count = 0;
                const callUpdateChainId = () => {
                  updateChainIdMutation({
                    chainId,
                  }).catch(async (error) => {
                    count++;
                    if (count < 4) {
                      await new Promise((resolve) => setTimeout(resolve, 1000 * (count + 1)));
                      callUpdateChainId();
                    }
                  });
                };
                setTimeout(() => {
                  if (verifyTokenQuery.isSuccess) {
                    callUpdateChainId();
                  }
                }, 2000);
              });
            // const userPublicKey = JSON.parse(localStorage.getItem(`signerData-${userAddress}`) ?? '');
            // console.log('🚀 ~ verify: ~ userPublicKey:', userPublicKey);
            // if (userPublicKey == '') {
            //   setCombinePublicKeyMutation.mutate({
            //     chainID: chainId,
            //     publicKey: userPublicKey.publicKey,
            //   });
            // }

            return true;
          } catch (error) {
            console.log('🚀 ~ verify: ~ error:', error);
            return false;
          }
        },

        signOut: async () => {
          if (accessToken) {
            logoutMutation.mutate();

            await queryClient.resetQueries({
              queryKey: userKeys.all,
            });
          }
        },
      }),
    [loginMutation, logoutMutation, userAddress, accessToken],
  );

  useEffect(() => {
    if (verifyTokenQuery.isError) {
      removeAuthTokens();
    }
  }, [verifyTokenQuery.isError]);

  let status: AuthenticationStatus;
  if (verifyTokenQuery.isSuccess) {
    status = 'authenticated';
  } else if (verifyTokenQuery.isFetching) {
    status = 'loading';
  } else {
    status = 'unauthenticated';
  }

  return (
    <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={status}>
      <AuthStatusProvider value={status}>{children}</AuthStatusProvider>
    </RainbowKitAuthenticationProvider>
  );
};

export const useAuth = () => {
  const authStatus = useAuthStatus();

  return {
    status: authStatus,
    isAuthenticated: authStatus === 'authenticated',
    isUnauthenticated: authStatus === 'unauthenticated',
    isPending: authStatus === 'loading',
  };
};

const rkDarkTheme = darkTheme();
const rkLightTheme = lightTheme();

export const RainbowProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useAppSelector((state) => state.theme);
  const rkTheme = theme === THEME_MODE.DARK ? rkDarkTheme : rkLightTheme;

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <SiweProvider>
          <RainbowKitProvider
            initialChain={chains.ethereum.id}
            theme={{ ...rkTheme, fonts: { body: 'Montserrat' } }}
          >
            <SignerProvider>
              <SocketProvider>{children}</SocketProvider>
            </SignerProvider>
          </RainbowKitProvider>
        </SiweProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
