import './styles.scss';
export const TermsOfUseContent = () => {
  return (
    <div className="text-letter-pale space-y-4 term">
      <p>
        VDEX is currently in <strong>BETA</strong>, meaning that some platform functions and
        guarantees may change over time.
      </p>
      <p>As part of the beta phase:</p>

      <ul>
        <li>
          <strong>VDEX</strong> security council can upgrade the contract
        </li>
        <li>
          <strong>Smart contracts are upgradeable</strong>, allowing for modifications during beta.
        </li>
        <li>
          <strong> A 24-hour withdrawal delay</strong> is in place to ensure security and stability,
          so that the security council can monitor possible incorrect balances due to bugs or
          exploits
        </li>
      </ul>

      <p>
        Users should understand these temporary limitations before engaging with the platform. These
        conditions will be removed upon full decentralization.
      </p>

      <h2>
        <strong>Privacy Policy</strong>
      </h2>

      <h3>
        <strong>Introduction</strong>
      </h3>

      <p>
        VDEX is a decentralized, self-custodial trading platform developed and operated under
        <strong>Virtual Labs, IBC</strong>, a Panama corporation ("Virtual Labs," "we," "us," or
        "our") respects your privacy and is committed to protecting it. This Privacy Policy explains
        what information we collect, how we use it, and your rights regarding data privacy when
        using <strong>https://vdex.trade</strong>
        and its associated subdomains. This policy supplements our <strong>Terms of Use</strong>,
        which is incorporated by reference.
      </p>

      <p>This Privacy Policy covers:</p>

      <ul>
        <li>Information collected through our website and trading platform.</li>
        <li>
          Data obtained through interactions such as emails, support requests, and community
          engagement.
        </li>
        <li>Compliance with Panama’s data protection regulations and global best practices.</li>
      </ul>

      <p>
        This policy does not apply to third-party platforms, wallets, or services that interact with
        Us, including blockchain networks.
      </p>

      <h2>
        <strong>Children Under 18</strong>
      </h2>

      <p>
        We are not intended for users under 18 years old. We do not knowingly collect personal data
        from minors. If we discover that we have collected information from a child under 18, we
        will take steps to delete it immediately. If you believe a minor has used our Platform,
        please contact us at <strong>support@vdex.trade</strong>.
      </p>

      <h2>
        <strong>Information We Collect</strong>
      </h2>
      <h3>
        <strong>1. Information You Provide</strong>
      </h3>
      <ul>
        <li>
          <strong>Wallet Information:</strong> Your public blockchain address to facilitate
          transactions.
        </li>
        <li>
          <strong>Communication Data:</strong> If you contact us via email, social media, or support
          tickets, we may retain records of your inquiries.
        </li>
        <li>
          <strong> User Preferences:</strong> Information voluntarily provided for platform
          personalization.
        </li>
      </ul>
      <h3>
        <strong>2. Information Collected Automatically</strong>
      </h3>
      <p>To improve platform performance, we may collect non-personal usage data, such as:</p>
      <ul>
        <li>IP Address (anonymized where applicable)</li>
        <li>Device Type & Browser Information</li>
        <li>Page Interaction Data (e.g., load times, button clicks, session duration)</li>
        <li>Error Reports & Debugging Logs</li>
      </ul>
      <p>
        This data is collected without personally identifying users and is used for platform
        analytics and security monitoring.
      </p>
      <h2>
        <strong>How We Use Your Information</strong>
      </h2>
      <p>We collects minimal data and only uses it to:</p>
      <ul>
        <li>Enable seamless smart contract interactions and transactions.</li>
        <li>Improve platform performance, security, and user experience.</li>
        <li>Provide technical support and respond to inquiries.</li>
        <li>Prevent fraudulent activities, security breaches, and illicit trading practices.</li>
      </ul>
      <h2>
        <strong>Disclosure of Your Information</strong>
      </h2>
      <p>
        We do not sell, rent, or trade user data. However, we may disclose certain non-personal
        information:
      </p>
      <ul>
        <li>
          <strong> Legal Compliance:</strong> If required by Panamanian law, court order, or
          government request.
        </li>
        <li>
          <strong>Security & Fraud Prevention:</strong> To protect{' '}
          <strong>Virtual Labs, IBC</strong> - (VDEX) users and the integrity of decentralized
          trading.
        </li>
        <li>
          <strong>Aggregated Analytics: </strong>Generalized usage statistics that do not identify
          individual users.
        </li>
      </ul>
      <h2>
        <strong>Privacy & The Blockchain</strong>
      </h2>
      <p>
        We operates entirely on blockchain technology, meaning certain transaction data is
        permanently recorded on public blockchains. This includes:
      </p>
      <ul>
        <li>Your wallet address (publicly visible on blockchain explorers)</li>
        <li>Transaction history on supported networks</li>
        <li>Smart contract interactions</li>
      </ul>
      <p>
        Blockchain transactions are immutable and cannot be erased, which is a fundamental feature
        of decentralized finance. Users should be aware that any on-chain activities remain
        permanently accessible.
      </p>
      <h2>
        <strong>Your Choices & Data Control</strong>
      </h2>
      <p>
        Because we do not store personal information, you control your data through your blockchain
        wallet. You may:
      </p>
      <ul>
        <li>Disconnect your wallet at any time to stop interacting with the platform.</li>
        <li>
          Use privacy tools such as VPNs, private wallets, or mixers (where legally permitted) to
          further enhance anonymity.
        </li>
        <li>Limit browser tracking by disabling cookies and site analytics.</li>
      </ul>
      <h2>
        <strong>Data Security</strong>
      </h2>
      <p>
        <strong>Virtual Labs, IBC</strong> employs industry-standard security measures, including:
      </p>
      <ul>
        <li>Encryption protocols to secure platform communications.</li>
        <li>
          Decentralized authentication mechanisms that remove the need for centralized data storage.
        </li>
        <li>Smart contract audits to prevent security vulnerabilities.</li>
      </ul>
      <p>
        However, as a <strong>non-custodial</strong> platform, security is ultimately the user's
        responsibility. Always safeguard your private keys, seed phrases, and wallet credentials.
      </p>
      <h2>
        <strong>Changes to This Privacy Policy</strong>
      </h2>
      <p>
        Virtual Labs, IBC reserves the right to update this policy at any time. Changes will be
        reflected on this page with a revised "Last Updated" date. Continued use of the platform
        signifies acceptance of the latest policy.
      </p>
      <h2>
        <strong>Contact Us</strong>
      </h2>
      <p>
        For privacy-related inquiries, reach out to us at <strong>support@vdex.trade</strong>.
      </p>
      <h2>
        <strong>Terms & Conditions</strong>
      </h2>
      <h3>
        <strong>Welcome to VDEX</strong>
      </h3>
      <p>
        These Terms & Conditions ("Terms") govern your access to and use of VDEX, including our
        website <strong>https://vdex.trade</strong> and related services. VDEX is owned and operated
        by <strong>Virtual Labs, IBC</strong>, a Panama corporation. By using VDEX, you agree to be
        bound by these Terms. If you do not agree, please refrain from using the platform.
      </p>
      <h3>
        <strong>1. Use of the Platform</strong>
      </h3>
      <ul>
        <li>
          <strong>Eligibility:</strong> You must be at least 18 years old to access or use{' '}
          <strong>Virtual Labs, IBC</strong> <strong>Plateforms</strong> - VDEX.
        </li>
        <li>
          <strong>Non-Custodial Trading</strong>: VDEX is a decentralized, self-custodial platform.
          Users retain control of their funds at all times.
        </li>
        <li>
          <strong>Prohibited Activities</strong>: Users agree not to engage in illegal or fraudulent
          activities, use bots to manipulate markets, or attempt unauthorized access to VDEX’s
          infrastructure.
        </li>
      </ul>
      <h3>
        <strong>2. Trading & Transactions</strong>
      </h3>
      <ul>
        <li>
          <strong>On-Chain Settlement</strong>: Trades occur on-chain and are final once confirmed.
        </li>
        <li>
          <strong>ZeroGas Transactions</strong>: Users are not required to pay blockchain gas fees.
        </li>
        <li>
          <strong>Liquidity & Risk</strong>: Market conditions, slippage, and volatility may impact
          trade execution. Users are responsible for understanding liquidity pools and order books.
        </li>
      </ul>
      <h3>
        <strong>3. Disclaimers & Liability</strong>
      </h3>
      <ul>
        <li>
          <strong>No Financial Guarantees: Virtual Labs, IBC</strong> does not guarantee profits or
          investment outcomes.
        </li>
        <li>
          <strong>Limitation of Liability:</strong> Virtual Labs, IBC is not responsible for trading
          losses, smart contract risks, or technical failures
        </li>
      </ul>
      <h3>
        <strong>4. Beta Terms</strong>
      </h3>
      <ul>
        <li>
          <strong> VDEX is currently in Beta.</strong>
        </li>
        <li>
          <strong>
            Smart contracts remain upgradable during this phase. Withdrawals are subject to a
            24-hour
          </strong>
        </li>
        <li>
          <strong>delay.</strong>
        </li>
      </ul>
      <p>Users acknowledge these conditions before participating on the platform.</p>
      <h3>
        <strong>5. Regulatory Compliance</strong>
      </h3>
      <ul>
        <li>
          <strong>Panama Legal Framework: Virtual Labs, IBC</strong> operates under Panama’s legal
          framework and complies with local financial regulations and data privacy laws.
        </li>
        <li>
          <strong>User Responsibility:</strong> Users must ensure their trading activities comply
          with their local jurisdiction.
        </li>
      </ul>
      <h3>
        <strong>6. Updates to These Terms</strong>
      </h3>
      <p>
        Virtual Labs, IBC may update these Terms periodically. Continued use of VDEX constitutes
        acceptance of the updated Terms.
      </p>
      <h3>
        <strong>7. Contact Information</strong>
      </h3>
      <p>
        For inquiries, contact us at <strong>support@vdex.trade.</strong>
      </p>
    </div>
  );
};
