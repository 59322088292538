import { useCheckRefCode } from 'src/services/user/check-ref-code';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, notification, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';

type AccessCodeModalProps = Pick<ModalProps, 'open' | 'onClose' | 'onOk'>;

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff', marginLeft: 8 }} spin />;

export const AccessCodeModal = ({ open, onClose, onOk }: AccessCodeModalProps) => {
  const [form] = Form.useForm();
  const { mutate: checkRefCode, isPending } = useCheckRefCode();
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get('referral');

  useEffect(() => {
    if (referralCode) {
      form.setFieldValue('code', referralCode);
    }
  }, [referralCode]);

  const closeModal = async (e: React.SyntheticEvent<Element, Event>) => {
    const enteredValue = form.getFieldValue('code');

    await checkRefCode(
      { referral_code: enteredValue },
      {
        onSuccess: (data) => {
          localStorage.setItem('accesscode', enteredValue);
            onClose!(e);
            notification.success({
              message: 'Success',
              description: 'Enjoy instant trading!',
              placement: 'bottomRight',
              style: { fontFamily: 'Montserrat' },
            });
        },
        onError: (error) => {
          notification.error({
              message: 'Code is wrong',
              description: 'Please try again with another code',
              placement: 'bottomRight',
              style: { fontFamily: 'Montserrat' },
          });
        },
      },
    );
  };

  return (
    <Modal
      className="position-tab-modal"
      centered
      title="Virtual Dex Access Code"
      open={open}
      footer={false}
      styles={{
        mask: {
          backgroundImage: "url('/images/dex-bg.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        wrapper: {
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)',
        },
      }}
    >
      <Form layout="vertical" form={form} onFinish={closeModal} initialValues={{ code: '' }}>
        <Typography.Paragraph>
          {' '}
          Please enter your special code to access instant trading!
        </Typography.Paragraph>
        <FormItem name="code">
          <Input
            styles={{ input: { textAlign: 'end' } }}
            size="large"
            style={{ textAlign: 'end' }}
            prefix="What is the secret word?"
            onChange={(e) => form.setFieldValue('size', e.target.value)}
          />
        </FormItem>
        <Button block htmlType="submit" size="large" type="primary">
          Confirm
        </Button>
      </Form>
    </Modal>
  );
};
