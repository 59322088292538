import { zodResolver } from '@hookform/resolvers/zod';
import { Modal, ModalProps, message, notification } from 'antd';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import secureLocalStorage from 'react-secure-storage';
import { delay } from 'src/helpers';
import { convertTimestampToDate } from 'src/helpers/date';
import { getActiveToken } from 'src/helpers/deposit';
import { decrypt, safeJSONParse } from 'src/helpers/encryption';
import { useVaultAddress } from 'src/hooks/useVaultAddress';
import { useAuth } from 'src/libs/rainbow';
import { handleTransactionError } from 'src/libs/wagmi';
import { useSetCombinePublicKey } from 'src/services/auth/set-combined-public-key';
import { buildApproveVariables, useApprove } from 'src/services/blockchain/approve';
import {
  UseDepositOptions,
  buildDepositVariables,
  useDeposit,
} from 'src/services/blockchain/deposit';
import { useErc20Allowance } from 'src/services/blockchain/get-allowance';
import { useErc20Balance } from 'src/services/blockchain/get-erc20-balance';
import { useSupportedTokens } from 'src/services/token/get-supported-tokens';
import { useFaucet } from 'src/services/user/get-faucet-tokens';
import { useGetPortfolio } from 'src/services/user/get-portfolio';
import { formatUnits, parseUnits } from 'viem';
import { useAccount, useChainId, useWaitForTransactionReceipt } from 'wagmi';
import { z } from 'zod';
import { Button, LoadingButton } from '../../ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../ui/form';
import { Input } from '../../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';

const depositFormSchema = z.object({
  token: z.string(),
  amount: z.string().refine(
    (val) => {
      const num = Number(val);
      return !isNaN(num) && num > 0;
    },
    { message: 'Amount must be a positive number' },
  ),
});

type DepositFormValues = z.infer<typeof depositFormSchema>;

type DepositModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  onDepositSuccess?: NonNullable<UseDepositOptions<unknown>['mutation']>['onSuccess'];
  onDepositError?: NonNullable<UseDepositOptions<unknown>['mutation']>['onError'];
  onDepositSettle?: () => void;
};

export const DepositModal = ({
  open,
  onClose,
  onDepositError,
  onDepositSuccess,
  onDepositSettle,
}: DepositModalProps) => {
  const { address: userAddress } = useAccount();
  const supportedTokensQuery = useSupportedTokens(1, false);
  const initialSelectedToken = supportedTokensQuery.data?.at?.(0)?.address;

  const form = useForm<DepositFormValues>({
    resolver: zodResolver(depositFormSchema),
    defaultValues: {
      token: initialSelectedToken,
      // amount: 0,
    },
  });

  const selectedTokenAddress = form.watch('token') ?? initialSelectedToken;
  const depositAmount = form.watch('amount');
  const chainId = useChainId();
  const erc20BalanceQuery = useErc20Balance({
    tokenAddress: selectedTokenAddress as `0x${string}`,
  });

  const vaultAddress = useVaultAddress();
  const setCombinePublicKeyMutation = useSetCombinePublicKey();
  const { mutateAsync: getTokenFromFaucetCall } = useFaucet();
  const allowanceQuery = useErc20Allowance({
    tokenAddress: selectedTokenAddress as `0x${string}`,
    contractAddress: vaultAddress,
  });
  const balanceQuery = useGetPortfolio(chainId);
  const approveMutation = useApprove();
  const waitForApproveReceiptQuery = useWaitForTransactionReceipt({
    hash: approveMutation.data,
    // confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    confirmations: 1,

    query: {
      enabled: !!approveMutation.data,
    },
  });

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (approveMutation.data) {
      approveMutation.reset();
    }
    if (depositMutation.data) {
      depositMutation.reset();
    }
  }, [chainId]);

  const refetchAllowance = allowanceQuery.refetch;
  useEffect(() => {
    if (waitForApproveReceiptQuery.status !== 'pending') {
      refetchAllowance();
    }
    if (waitForApproveReceiptQuery.status === 'success') {
      notification.success({
        message: 'Approve Successful',
        description: 'Your approval has been successful',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    }
  }, [waitForApproveReceiptQuery.status, refetchAllowance]);

  const getTokens = async () => {
    try {
      const faucetData = await getTokenFromFaucetCall({ chainID: chainId });
      // console.log('🚀 ~ getTokens ~ faucetData:', faucetData);
      //@ts-ignore
      if (faucetData.nextFaucetCall) {
        //@ts-ignore
        const nextCall = convertTimestampToDate(faucetData.nextFaucetCall!);
        notification.error({
          message: 'Something went wrong',
          description: `Your faucet call could not be completed. Please try again at ${nextCall}`,
          placement: 'bottomRight',
          style: { fontFamily: 'Montserrat' },
        });
      } else {
        notification.success({
          message: 'Faucet call Successful',
          description: 'Your test tokens are on their way!',
          placement: 'bottomRight',
          style: { fontFamily: 'Montserrat' },
        });

        await new Promise((resolve) => setTimeout(resolve, 10000));

        const refetchCount = 15;
        const refetchInterval = 1000;

        for (let i = 0; i < refetchCount; i++) {
          await new Promise((resolve) => setTimeout(resolve, refetchInterval));
          await refetchErc20Balance();
          // console.log(`Refetched balance (${i + 1}/${refetchCount}/${erc20BalanceQuery.data})`);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: 'Your faucet call could not be completed. Please try again or contact support',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    }
  };

  const depositMutation = useDeposit();
  const waitForDepositReceiptQuery = useWaitForTransactionReceipt({
    hash: depositMutation.data,
    // confirmations: Number(process.env.REACT_APP_BLOCK_CONFIRMATION!),
    confirmations: 1,
    query: {
      enabled: !!depositMutation.data,
    },
  });

  const refetchErc20Balance = erc20BalanceQuery.refetch;

  useEffect(() => {
    if (waitForDepositReceiptQuery.status === 'pending') {
      return;
    }
    if (waitForDepositReceiptQuery.status === 'success') {
      notification.success({
        message: 'Deposit Successful',
        description: 'Your deposit has been successful',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
      form.reset();
      form.resetField('amount');
      if (isAuthenticated) {
        balanceQuery.refetch();
      }

      // setTimeout(() => {
      //   queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) });
      // }, 10000);

      // setInterval(() => {
      //   queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) });
      // }, 1000);

      // queryClient.invalidateQueries({ queryKey: userKeys.allBalances(userAddress!) }),
      // refetchErc20Balance();
    }

    (async () => {
      onDepositSettle?.();

      await delay(2000);
      // notification.success({
      //   message: 'Deposit Successful',
      //   description: 'Your deposit has been successful',
      //   placement: 'bottomRight',
      // });
      const storedData = secureLocalStorage.getItem(`signerData-${userAddress}`) as string;
      const decryptedData = decrypt(storedData, 'vDex', userAddress as string);

      if (decryptedData) {
        const parsedData = safeJSONParse(decryptedData);
        // console.log('🚀 ~ parsedData:', parsedData);
        // const userPublicKey = JSON.parse(decryptedData);

        setCombinePublicKeyMutation.mutate({
          chainID: chainId,
          publicKey: parsedData.publicKey,
        });
      }
      await Promise.allSettled([refetchErc20Balance()]);
    })();
  }, [waitForDepositReceiptQuery.status, refetchErc20Balance, isAuthenticated]);

  const activeToken = useMemo(
    () => getActiveToken(supportedTokensQuery.data ?? [], selectedTokenAddress! as `0x${string}`),
    [supportedTokensQuery.data, selectedTokenAddress, initialSelectedToken],
  );

  // console.log('🚀 ~ activeToken:', activeToken);

  useEffect(() => {
    if (initialSelectedToken) {
      form.setValue('token', initialSelectedToken);
    }
  }, [initialSelectedToken, form, open]);

  useEffect(() => {
    if (form.getValues('amount')) {
      form.reset();
    }
  }, []);

  let maxDepositSize: number;
  if (erc20BalanceQuery.data === undefined) {
    maxDepositSize = 0;
  } else {
    maxDepositSize = Number(
      formatUnits(erc20BalanceQuery.data ?? BigInt(0), activeToken?.decimal ?? 18),
    );
  }

  const handleSubmit = async (values: DepositFormValues) => {
    if (values.amount === null || Number(values.amount) === 0) {
      form.setError('amount', { type: 'manual', message: 'Amount is required' });
      return;
    }

    try {
      if (allowanceSufficient) {
        await handleDeposit(values);
      } else {
        await handleApprove(values);
      }
    } catch (error) {
      console.error('Transaction failed:', error);
      message.error('Transaction failed. Please try again.');
      // onDepositError?.(error);
    }
  };

  const handleDeposit = async (values: DepositFormValues) => {
    if (Number(values.amount)! > maxDepositSize) {
      form.setError('amount', { type: 'manual', message: 'Amount exceeds available balance' });
      return;
    }
    console.log('values.token', values.token);

    await depositMutation.writeContract(
      buildDepositVariables({
        amount: parseUnits(values.amount!.toString(), activeToken?.decimal ?? 18),
        tokenAddress: values.token as `0x${string}`,
        vaultAddress,
      }),
      {
        onSuccess: onDepositSuccess,
        onError: onDepositError,
      },
    );
  };

  const handleApprove = async (values: DepositFormValues) => {
    // Brutal hack to make USDC work bcs USDC has 6 decimals and the code was setting it to 18
    // Fix it in the future in the right way
    const params = buildApproveVariables({
      amount: parseUnits(values.amount!.toString(), activeToken?.decimal ?? 18),
      tokenAddress: values.token as `0x${string}`,
      vaultAddress,
    });
    await approveMutation.writeContract(params, {
      onSuccess: async (data) => {},
      onError: handleTransactionError((error) => {
        try {
          console.log('🚀 ~ handleApprove ~ error:', error);
          const errorMessage = typeof error?.message === 'string' ? error.message : '';
          const detailsMatch = errorMessage.match(/Details:([\s\S]*?)Version:/);
          const detailedMessage = detailsMatch
            ? detailsMatch[1]?.trim()
            : 'An unexpected error occurred';

          if (errorMessage.includes('insufficient funds')) {
            message.error('Not enough funds to complete this transaction.');
          } else {
            message.error(`Approve failed: ${detailedMessage}`);
          }
        } catch (err) {
          console.error('Error handling transaction error:', err);
        }
      }),
    });
  };

  const _depositAmount = new BigNumber(depositAmount!).multipliedBy(
      Math.pow(10, activeToken?.decimal ?? 18),
  );
  const allowanceSufficient = new BigNumber(
    allowanceQuery.data?.toString() ?? 0,
  ).isGreaterThanOrEqualTo(_depositAmount);

  const renderFooter = () => {
    return <></>;
  };

  useEffect(() => {
    return () => {
      form.reset();
      form.setValue('amount', '');
      if (isAuthenticated) {
        balanceQuery.refetch();
      }
    };
  }, [open, isAuthenticated]);

  return (
    <Modal
      title="Deposit"
      open={open}
      onCancel={onClose}
      footer={renderFooter}
      className="z-10 font-montserrat"
    >
      <FormProvider {...form}>
        <form className="z-50" onSubmit={form.handleSubmit(handleSubmit)}>
          <FormField
            control={form.control}
            name="token"
            render={({ field }) => (
              <FormItem className="z-50">
                <FormLabel>Asset</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    // refetchAllowance();
                    // refetchErc20Balance();
                    // console.log('tradevall', value, tifValue);
                  }}
                  //  onValueChange={field.onChange}
                  value={field.value}
                >
                  <FormControl className="">
                    <SelectTrigger className="dark:border-none">
                      <SelectValue placeholder="Select Asset" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent
                    className="z-[1000] "
                    position="popper"
                    sideOffset={5}
                    style={{ position: 'relative' }}
                  >
                    {supportedTokensQuery.data?.map(({ address, token_id }) => (
                      <SelectItem key={address} value={address}>
                        {token_id}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem className="mt-2">
                <FormLabel>Amount</FormLabel>
                <FormControl className="">
                  <div className="relative">
                    <Input
                      type="text"
                      {...field}
                      autoComplete="off"
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   if (isNaN(parseFloat(value))) {
                      //     form.resetField('amount');
                      //     return;
                      //   }
                      //   field.onChange(value === '' ? 0 : parseFloat(value));
                      // }}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow empty string or valid decimal numbers
                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                          field.onChange(value);
                        }
                      }}
                      value={field.value === null ? '' : field.value}
                    />
                    <div className="absolute top-0 right-0 flex items-center">
                      <Button
                        onClick={() => {
                          form.setValue('amount', maxDepositSize.toString());
                        }}
                        variant="link"
                        type="button"
                        className="text-blue-500 rounded-none dark:text-white"
                      >
                        Max
                      </Button>
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-between mt-4">
            <p className="font-montserrat">Available</p>
            <p className="font-montserrat">
              {erc20BalanceQuery.data !== undefined
                ? formatUnits(erc20BalanceQuery.data, activeToken?.decimal ?? 18)
                : 0}{' '}
              {activeToken?.token_id}
            </p>
          </div>

          <div className="text-xs mt-2 flex gap-3">
            Note:
            <ul className="list-disc list-inside">
              <li>
                All withdrawals are subject to a 24-hour processing period. During beta, the
                contract can be upgraded by the protocol team
              </li>
              <li>Deposit flow is two step process</li>
            </ul>
          </div>

          <div className="flex flex-col items-end gap-2 flex-gap">
            {allowanceSufficient ? (
              <div className="flex items-end justify-end gap-3">
                <Button type="button" variant={'outline'} className="text-black" onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
                  disabled={depositMutation.isPending || waitForDepositReceiptQuery.isFetching}
                  loading={depositMutation.isPending || waitForDepositReceiptQuery.isFetching}
                >
                  Confirm Deposit
                </LoadingButton>
              </div>
            ) : (
              <div className="w-full flex items-end justify-between gap-3 mt-3">
                <Button
                  type="button"
                  variant="outline"
                  className="text-black w-fit"
                  onClick={getTokens}
                >
                  Faucet
                </Button>
                <div className="flex flex-col justify-end gap-3">
                  <LoadingButton
                    type="submit"
                    className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
                    // onClick={handleApprove}
                    loading={
                      approveMutation.isPending ||
                      waitForApproveReceiptQuery.isFetching ||
                      allowanceQuery.isFetching
                    }
                    disabled={
                      approveMutation.isPending ||
                      waitForApproveReceiptQuery.isFetching ||
                      allowanceQuery.isFetching
                    }
                  >
                    Approve
                  </LoadingButton>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
